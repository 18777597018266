import '../css/flipbook_account_title_page.css';
import 'jquery-migrate';
import $ from 'jquery';

window.jQuery = window.$j = window.$ = $; // Needed for bootstrap-tooltip, thickbox
require('../js/static/bootstrap-tooltip'); // Use require here to ensure bootsrap loads after jQuery setup

/**
 * We have to re-add the deprecated browser object to jQuery in order for
 * thickbox to work. Its okay if its empty;
 */
$.browser = {};

function loadArchives(button, urlBase) {
  if (!window.tb_show) {
    return;
  }
  window.tb_show(
    button.dataset.titleName,
    `${urlBase}read/archives/issue/${button.dataset.titleId}/0/?width=400&height=450`,
  );
}

function init({ g_img_url: imgUrl, g_webroot: urlBase }) {
  window.g_img_url = imgUrl; // Needed for thickbox >:(
  require('../js/flipbook3/thickbox'); // Use require here to ensure thickbox loads after init
  $(document).ready(function () {
    $('[data-toggle=tooltip]').tooltip();
    $('#open-archive').on('click', function () {
      loadArchives(this, urlBase);
    });
  });
}

export { init };
